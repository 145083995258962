// app/providers.tsx
'use client';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';

export function PHProvider({
  children,
  NEXT_PUBLIC_POSTHOG_KEY,
  NEXT_PUBLIC_POSTHOG_HOST,
}: {
  children: React.ReactNode;
  NEXT_PUBLIC_POSTHOG_KEY: string | undefined;
  NEXT_PUBLIC_POSTHOG_HOST: string | undefined;
}) {
  console.log('NEXT_PUBLIC_POSTHOG_KEY', NEXT_PUBLIC_POSTHOG_KEY);
  useEffect(() => {
    console.log('NEXT_PUBLIC_POSTHOG_KEY', NEXT_PUBLIC_POSTHOG_KEY);
    if (NEXT_PUBLIC_POSTHOG_KEY) {
      console.log('calling posthog.init');
      posthog.init(NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: NEXT_PUBLIC_POSTHOG_HOST,
        person_profiles: 'always',
        capture_pageview: false, // Disable automatic pageview capture, as we capture manually
        capture_pageleave: true, // Enable pageleave capture
      });
    }
  }, []);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
